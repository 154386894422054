<template>
    <div class="stepsdemo-content">
        <Card>
           <template v-slot:title>
                {{$t('Tipo de Póliza')}}
            </template>
            <template v-slot:subtitle>
                {{$t('solicitada')}}
            </template>
            <template v-slot:content>
                <div class="p-fluid p-formgrid p-grid">
					<div class="p-field p-col-12 p-md-4">
						<label for="state">{{$t('Tipo Póliza')}}</label>
						<Dropdown v-model="tipopoliza" :options="tipopolizas" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')"></Dropdown>
                        <small v-show="validationErrors.tipopoliza && submitted" class="p-error">{{$t('required')}}</small>
					</div>
					<div class="p-field p-col-12 p-md-8" v-if="tipopoliza">
                        <div v-if="tipopoliza && i18n.locale() == 'es'">{{tipopoliza.descrip1}}</div>
                        <div v-if="tipopoliza && i18n.locale() == 'en'">{{tipopoliza.descrip2}}</div>
					</div>
                </div>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-6">		
                        <h5>{{$t('Documentos Personales')}}</h5>
						<DataTable :value="documentos" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
								:rows="5" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
							<Column :header="$t('Número ID')">
								<template #body="slotProps">
									{{slotProps.data.numero}}
								</template>
							</Column>
							<Column :header="$t('Imagen')">
								<template #body="slotProps">
									<img style="width: 60px;" :src="url.upload+slotProps.data.img" v-if="slotProps.data.verif"/>
								</template>
							</Column>
							<Column>
								<template #body="slotProps">
									<Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="Delete1(slotProps.data)"/>
								</template>
							</Column>
						</DataTable>
                        <Button :label="$t('Agregar')" icon="pi pi-plus-circle" class="p-button-secondary" @click="Document1()" style="max-width:150px;"/>
                        <small v-show="validationErrors.documentos && submitted" class="p-error">{{$t('required')}}</small>
					</div>
                    <div class="p-field p-col-12 p-md-6">
                        <h5>{{$t('Prueba de Risedencia')}}</h5>
						<DataTable :value="residencias" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
								:rows="5" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
							<Column :header="$t('Tipo de documento')">
								<template #body="slotProps">
									{{slotProps.data.tipo}}
								</template>
							</Column>
							<Column :header="$t('Imagen')">
								<template #body="slotProps">
									<img style="width: 60px;" :src="url.upload+slotProps.data.img" v-if="slotProps.data.verif"/>
								</template>
							</Column>
							<Column>
								<template #body="slotProps">
									<Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="Delete2(slotProps.data)"/>
								</template>
							</Column>
						</DataTable>
						<Button :label="$t('Agregar')" icon="pi pi-plus-circle" class="p-button-secondary" @click="Document2()" style="max-width:150px;"/>
                        <small v-show="validationErrors.residencias && submitted" class="p-error">{{$t('required')}}</small>
					</div>
                </div>
            </template>
            <template v-slot:footer>
                <div class="p-grid p-nogutter p-justify-between">
                    <Button :label="$t('Anterior')" @click="prevPage()" icon="pi pi-angle-left" />
                    <Button :label="$t('Siguiente')" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" />
                </div>
            </template>
        </Card>

        <Dialog v-model:visible="DocumentoDialog1" :style="{width: size}" :header="$t('Agregar')" :modal="true" :maximizable="true"> 
            <br>
            <div class="confirmation-content">
                <div class="p-fluid p-formgrid p-grid">
					<div class="p-field p-col-6">
						<label>{{$t('Número ID')}}</label>
						<InputText v-model="documento.numero" type="text"/>
                        <small v-show="validationErrors.numero" class="p-error">{{$t('required')}}</small>
					</div>
					<div class="p-field p-col-4">
						<label>{{$t('Imagen')}}</label>
						<FileUpload :chooseLabel="$t('Subir Foto')+ ' JPG,JPEG'" uploadLabel="Cargar" mode="basic" :name="documento.img" :url="url.api+'upload.php'"  accept="image/jpeg,image/jpg" :maxFileSize="4000000" @upload="myUploader1(documento.img)" :auto="true"/>
                        <small class="p-error">{{$t('tamaño máximo')+": 4MB"}}</small><br>
                        <small v-show="validationErrors.foto" class="p-error">{{$t('required')}}</small>
						<img style="width: 60px;" :src="url.upload+documento.img" v-if="foto1"/>
					</div>
                </div>
            </div>
            <template #footer>
                <Button :label="$t('Agregar')" icon="pi pi-plus-circle" class="p-button-secondary" @click="Agregar1" />
                <Button :label="$t('Cancelar')" icon="pi pi-times" class="p-button-danger" @click="DocumentoDialog1 = false"/>
            </template>
        </Dialog>
        <Dialog v-model:visible="DocumentoDialog2" :style="{width: size}" :header="$t('Agregar')" :modal="true" :maximizable="true"> 
            <br>
            <div class="confirmation-content">
                <div class="p-fluid p-formgrid p-grid">
					<div class="p-field p-col-6">
						<label>{{$t('Tipo de documento')}}</label>
						<InputText v-model="residencia.tipo" type="text"/>
                        <small v-show="validationErrors.tipo" class="p-error">{{$t('required')}}</small>
					</div>
					<div class="p-field p-col-4">
						<label>{{$t('Imagen')}}</label>
						<FileUpload :chooseLabel="$t('Subir Foto')+ ' JPG,JPEG'" uploadLabel="Cargar" mode="basic" :name="residencia.img" :url="url.api+'upload.php'"  accept="image/jpeg,image/jpg" :maxFileSize="4000000" @upload="myUploader2(residencia.img)" :auto="true"/>
                        <small class="p-error">{{$t('tamaño máximo')+": 4MB"}}</small><br>
                        <small v-show="validationErrors.foto" class="p-error">{{$t('required')}}</small>
						<img style="width: 60px;" :src="url.upload+residencia.img" v-if="foto2"/>
					</div>
                </div>
            </div>
            <template #footer>
                <Button :label="$t('Agregar')" icon="pi pi-plus-circle" class="p-button-secondary" @click="Agregar2" />
                <Button :label="$t('Cancelar')" icon="pi pi-times" class="p-button-danger" @click="DocumentoDialog2 = false"/>
            </template>
        </Dialog>



    </div>
</template>

<script>
import API from "@/service/ApiConsulta";
import { useI18nPlugin } from '@unify/vuex-i18n';
import Token from "uuid-token-generator";
import url from "@/service/_URL";

export default {
    props: {
        formData: Object
    },
   data () {
        return {
            submitted: false,
            validationErrors: {},
            tipopoliza: null,
			tipopolizas: [],
            Dialog: false,
            size: '40vw',
			DocumentoDialog1: false,
			DocumentoDialog2: false,
			documentos: [],
			documento: {
				numero: null,
				img: null,			
				verif: false				
			},
			residencias: [],
			redidencia: {
				tipo: null,
				img: null,			
				verif: false				
			},
            foto1: false	,			
            foto2: false				

        }
    },
	created() {
		this.url = url;
        if (this.$store.state.mobile){this.size = '80vw';}
		this.i18n = useI18nPlugin();
		this.$store.commit('Loading');
		const Consulta = new API('Cotizacion');
		Consulta.Procesar('Salud',{
			caso: 	'tipopoliza',
			fecha: this.fdate(this.formData.datos.fecha),
		}).then(response => {
            //this.$store.state.error = response;
			this.tipopolizas = response.result;	
			this.$store.commit('Loading');
		});
	},
    methods: {
        fdate(dato){
            const [day, month, year] = dato.split('/');
            return new Date(+year, +month - 1, +day);
        },
        Document1() {
			this.foto = false;
			this.documento = {
				numero: null,
				verif: false				
			};
			if(this.documentos.length==0){
				this.documento.numero = this.formData.datos.code;
			}
			const tokgen = new Token(256);
			this.documento.img = tokgen.generate();
			this.DocumentoDialog1 = true;
		},
		myUploader1(img) {
			this.$store.commit('Loading');
			const Consulta = new API('Cotizacion');
			Consulta.Procesar('Salud',{
				caso: 	'RevFoto',
				foto: img,
			}).then(response => {
                //this.$store.state.error = response;	
				if(response.result){
					this.foto1 = response.result;
					this.documento.img = response.result;
				} else {
					this.$toast.add({
						severity:'warn', 
						summary: this.$t('Advertencia'), 
						detail: this.$t('Error en Foto'), 
						life: 3000
					});
				}
				this.$store.commit('Loading');
			});
		},
		Agregar1() {
            if (this.foto1) {
				this.$store.commit('Loading');
				this.documento.verif = true;
				this.documentos.push(this.documento);
				this.DocumentoDialog1 = false;
				this.$store.commit('Loading');
            }
		},
		Delete1(selec) {
			this.documentos = this.documentos.filter(val => val !== selec);
		},
        Document2() {
			this.foto = false;
			this.residencia = {
				tipo: null,
				verif: false				
			};
			const tokgen = new Token(256);
			this.residencia.img = tokgen.generate();
			this.DocumentoDialog2 = true;
		},
		myUploader2(img) {
			this.$store.commit('Loading');
			const Consulta = new API('Cotizacion');
			Consulta.Procesar('Salud',{
				caso: 	'RevFoto',
				foto: img,
			}).then(response => {
                //this.$store.state.error = response;	
				if(response.result){
					this.foto2 = response.result;
					this.residencia.img  = response.result;
				} else {
					this.$toast.add({
						severity:'warn', 
						summary: this.$t('Advertencia'), 
						detail: this.$t('Error en Foto'), 
						life: 3000
					});
				}
				this.$store.commit('Loading');
			});
		},
		Agregar2() {
            if (this.foto2) {
				this.$store.commit('Loading');
				this.residencia.verif = true;
				this.residencias.push(this.residencia);
				this.DocumentoDialog2 = false;
				this.$store.commit('Loading');
            }
		},
		Delete2(selec) {
			this.residencias = this.residencias.filter(val => val !== selec);
		},

        nextPage() {
            this.submitted = true;
            if (this.validateForm() ) {
                this.$emit('next-page', {formData: {tipopoliza: this.tipopoliza, documentos: this.documentos[0],residencias: this.residencias[0]}, pageIndex: 1});
            }
        },
        prevPage() {
            this.$emit('prev-page', {pageIndex: 1});
        },
        validateForm() {
            if (this.tipopoliza==null)
               this.validationErrors['tipopoliza'] = true;
            else
               delete this.validationErrors['tipopoliza'];
            if (this.documentos.length==0)
               this.validationErrors['documentos'] = true;
            else
               delete this.validationErrors['documentos'];
            if (this.residencias.length==0)
               this.validationErrors['residencias'] = true;
            else
               delete this.validationErrors['residencias'];


            return !Object.keys(this.validationErrors).length;
        }
    }
}
</script>